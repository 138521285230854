import SmoothScroll from "./SmoothScroll";
import "./LandingPage.scss";
import Greeting from "./Greeting";
import SecondGreeting from "./SecondGreeting";
import DownloadImages, {DownloadImagesContent} from "./DownloadImages";
import Firework from "./Firework";
import WaitScreen from "./WaitScreen";
import Message from "./Message";

export default function LandingPage() {
    return (
        <>
            <SmoothScroll>
                <Greeting/>
                <SecondGreeting/>
                <Message/>
                {/*<DownloadImages/>*/}
                {/*<Firework/>*/}
            </SmoothScroll>
            {/*<DownloadImagesContent/>*/}
            {/*<WaitScreen/>*/}
        </>
    )
}
