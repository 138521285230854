import React, {useEffect, useRef} from "react";
import Scrollbar, {ScrollbarPlugin} from "smooth-scrollbar";
import {useAppDispatch} from "../../redux/store";
import {setScrollY} from "../../redux/layoutSlice";


type Momentum = {
    x: number,
    y: number,
};

export default function SmoothScroll({children}: { children: React.ReactNode }) {

    const dispatch = useAppDispatch();

    const smoothScroll = useRef<HTMLDivElement>(null);

    useEffect(() => {
        class MyPlugin extends ScrollbarPlugin {
            static pluginName = 'MyPlugin';

            onRender(remainMomentum: Momentum) {
                const scrollY = this.scrollbar.offset.y;
                dispatch(setScrollY(scrollY));
            }
        }

        Scrollbar.use(MyPlugin);
        // @ts-ignore
        Scrollbar.init(document.querySelector('.SmoothScroll'), {
            damping: 0.06,
            plugins: [MyPlugin],
        });
        }, [])

    return (
        <div className={"SmoothScroll"} ref={smoothScroll}>
            <div>
                {children}
            </div>
        </div>
    )
}


