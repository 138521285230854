import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface layoutSliceInterface {
    scrollY: number,
    downloadYProgress: number,
}

const initialState: layoutSliceInterface = {
    scrollY: 0,
    downloadYProgress: 0,
};

export const layoutSlice = createSlice({
    name: 'layoutSlice',
    initialState,
    reducers: {
        setScrollY: (state, action: PayloadAction<number>) => {
            state.scrollY = action.payload;
        },
        setDownloadYProgress: (state, action: PayloadAction<number>) => {
            state.downloadYProgress = action.payload;
        }
    }
});

export const {setScrollY, setDownloadYProgress} = layoutSlice.actions;
export default layoutSlice.reducer;
