import React, {useRef} from "react";
import {motion, useTransform} from "framer-motion";
import useLandingScroll from "../../hooks/useLandingScroll";


export default function Message() {
    const MessageSection = useRef<HTMLDivElement>(null);
    const progress = useLandingScroll({target: MessageSection, startProgress: false});
    const scaleYWave = useTransform(progress, [0, 1], [0, 1]);

    return (
        <div ref={MessageSection} className={"MessageSection"}>
        
            <div className={'content'}>
                <i>Dear my love, xx/04/2024</i>
                <br/>
                <br/>
                It's been a long time since we last talked. I hope you are doing well. I miss you so much. I miss your
                smile, your voice, your laugh, your touch, your hug, your kiss, your everything. I miss you every single
                day. I miss you every single moment. I miss you every single heartbeat. I miss you every single breath.
                I miss you every single dream. I miss you every single thought
                <br/>
                <br/>
                I know you are happy, and I am happy for you.
                <br/>
                <br/>
                But do you know that you-now are not the one who I am in love with?
                <br/>
                <br/>
                I'm in love with the white tulip, who always believes in love, who is happy with romantic things, who
                loves to spend every single second of the day talking with me even when I'm not there, even when I'm
                working, even when you are with your friends...
                <br/>
                <br/>
                It took a half year for me to realize that you-now are changed, now you are independent, and you only
                love yourself.
                <br/>
                Only you in your heart and it is not large enough to accommodate me anymore.
                <br/>
                <br/>
                I'm not sad, I'm not angry, I'm not disappointed, I'm not regretful.
                <br/>
                <br/>
                But I don't wanna be a choice besides your freedom or your friends or your other options, I don't wanna
                be jealous when you are with someone else, If I can't be your everything, I don't wanna be your
                anything.
                <br/>
                <br/>
                I'm not me in the past, also not like any guys beside you,
                I'm not a guy who can be with you in a short.
                Can not be your "mập mờ" like us before.
                Now I always think of us in the future, at the wedding, always serious with you and love.
                <br/>
                <br/>
                I know you are Philophobia, you don't want a love like mine anymore.
                <br/> <br/>
                But I can not give you love like H, I do not only need a body or a gamer friend and let you do anything
                without jealous. Then, after you, he can be in love again in a short time. That's definitely not me-now.
                <br/><br/>
                I need a soul like a white tulip, like you in the past.
                That's why I said you-now are not the one who I in love with.
                <br/>
                It's not easy to say goodbye.
                <br/>
                I'm happy for you, I'm happy for me.
                <br/>
                I'm happy for us.
                <br/>
                <br/>
                Goodbye my love.
                <br/>
                <br/>
                <br/>
                <br/>
                <div>
                    <i>
                        To the White Tulip, who I in love with.
                        <br/>
                        Text me if you are back in her soul.
                        <br/>
                        <br/>
                        To Trân, who chooses to kill the white tulip soul.
                        <br/>
                        I hope luck and happiness will be with you.
                    </i>
                </div>
                <br/>
                <br/>
                <div style={{textAlign: "right"}}>
                    <i>From the guy who loves you more than you can imagine.</i>
                </div>

                <br/>
                <br/>

                <hr style={{width: '100%', borderBottom: '1px solid gray'}}/>

                <br/>
                <br/>
                
                <div>
                    <i>Dear my babe, 05/06/2024</i>
                    <br/>
                    <br/>
                    I miss you so much.
                    <br/>
                    <br/>
                    <div style={{textAlign: "right"}}>
                        <i>From GK.</i>
                    </div>
                </div>
            </div>
        </div>
    )
}
