import React, {useEffect, useState} from "react";
import {motion, useTransform} from "framer-motion";
import useLandingScroll from "../../hooks/useLandingScroll";
import iphonewall from '../../assets/iphonewall.jpg'
import {FaFacebookF, FaInstagram, FaYoutube} from "react-icons/fa6";
import {ImYoutube2} from "react-icons/im";
import {FaTelegramPlane} from "react-icons/fa";


export default function SecondGreeting() {
    const SecondGreetingRef = React.useRef<HTMLDivElement>(null);
    const progress = useLandingScroll({target: SecondGreetingRef, startProgress: true});
    const progressEnd = useLandingScroll({target: SecondGreetingRef, startProgress: false});
    const opacity = useTransform(progress, [0.7, 0.9], [0, 1]);
    const scale = useTransform(progress, [0.7, 0.9], [1.2, 1]);
    useEffect(() => {
        setTimeout(() => {
        }, 1000)
    }, []);
    const [visible, setVisible] = React.useState(false);
    const [visibleIphone, setVisibleIphone] = React.useState(false);
    useEffect(() => {
        const unsub = progress.on('change', (value) => {
            if (value > 0.3) {
                setVisible(true);
            } else {
                setVisible(false);
            }

            if (value > 0.5) {
                setVisibleIphone(true);
            } else {
                setVisibleIphone(false);
            }
        })

        return () => {
            unsub();
        }
    }, [progress]);

    const [isInViewIcons, setInViewIcons] = useState(false);

    useEffect(() => {
        const unsub = progressEnd.on('change', (value) => {
                setInViewIcons(value > 0.2);
            }
        )

        return () => {
            unsub();
        }
    }, [progressEnd]);

    const defaultIconAnimate = {scale: 0.5, rotate: -200, opacity: 0, top: '50%', left: '50%'};

    const slowAnimateRange = [0.3, 1];


    const yIcon1 = useTransform(progressEnd, slowAnimateRange, [0, window.screen.height * 0.5]);
    const yIcon2 = useTransform(progressEnd, slowAnimateRange, [0, window.screen.height * 0.4]);
    const yIcon3 = useTransform(progressEnd, slowAnimateRange, [0, window.screen.height * 0.2]);
    const yIcon4 = useTransform(progressEnd, slowAnimateRange, [0, window.screen.height * 0.3]);
    const yPhone = useTransform(progressEnd, slowAnimateRange, [0, window.screen.height * 0.3])
    const rotatePhone = useTransform(progressEnd, slowAnimateRange, [0, 20])
    const scaleY = useTransform(progressEnd, [0, 2], [0, 1]);

    return (
        <div ref={SecondGreetingRef} className={"SecondGreetingLandingSection"}>
            <div className={"contentSection"}>
                <motion.div
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: visible ? 1 : 0, y: visible ? 0 : 50}}
                    transition={{damping: 10}}
                    className={'justasecond'}>
                    Just a second
                </motion.div>
                <motion.div
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: visible ? 1 : 0, y: visible ? 0 : 50}}
                    transition={{delay: 0.3, damping: 10}}
                    className={"settingup"}>
                    We are setting up your experience
                </motion.div>
                <motion.div
                    className={"IconsContainer"}
                    animate={visibleIphone ? {opacity: 1, y: 0, scale: 1} : {opacity: 0, y: 50, scale: 0}}
                    transition={{type: 'spring', mass: 0.5, stiffness: 50, damping: 10}}
                >
                    <div className={"icons"}>
                        <motion.div
                            style={{y: yIcon1}}
                            animate={isInViewIcons ? {scale: 1, rotate: -20, top: 0, left: -50} : defaultIconAnimate}
                            transition={{type: 'spring', mass: 0.5, stiffness: 50}}
                            className={"icon iconInsta"}>
                            <FaInstagram/>
                        </motion.div>
                        <motion.div
                            style={{y: yIcon2}}
                            animate={isInViewIcons ? {
                                scale: 1,
                                rotate: 25,
                                top: -10,
                                left: '105%'
                            } : defaultIconAnimate}
                            transition={{type: 'spring', mass: 0.5, stiffness: 50}}
                            className={"icon iconFb"}>
                            <FaFacebookF/>
                        </motion.div>

                        <motion.div
                            style={{y: yIcon3}}
                            animate={isInViewIcons ? {
                                scale: 1,
                                rotate: -30,
                                top: '85%',
                                left: '90%'
                            } : defaultIconAnimate}
                            transition={{type: 'spring', mass: 0.5, stiffness: 50}}
                            className={"icon iconTele"}>
                            <FaTelegramPlane/>
                        </motion.div>
                        <motion.div
                            style={{y: yIcon4}}
                            animate={isInViewIcons ? {
                                scale: 1,
                                rotate: -30,
                                top: '85%',
                                left: '-30%'
                            } : defaultIconAnimate}
                            transition={{type: 'spring', mass: 0.5, stiffness: 50}}
                            className={"icon iconYoutube"}>
                            <ImYoutube2/>
                        </motion.div>
                    </div>
                    <motion.div
                        style={{rotate: rotatePhone, y: yPhone}}
                        className={"iphone"}>
                        <motion.img
                            style={{opacity, scale}}
                            src={iphonewall} alt={"iphonewall"} className={"iphonewall"}/>
                    </motion.div>
                </motion.div>
            </div>
            <motion.div
                className={"sectionBg"}
                style={{scaleY}}>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 481">
                    <path className="cls-1"
                          fill={"#fff"}
                          d="M1366,489.83c0,188.97-1366,188.97-1366,0S329.51,11,683.56,11s682.44,289.86,682.44,478.83Z"/>
                </svg>
            </motion.div>
        </div>
    )
}
