import React, {useEffect, useMemo, useRef, useState} from "react";
import {motion, useTransform} from 'framer-motion'
import {FaAngleDown} from "react-icons/fa6";
import useLandingScroll from "../../hooks/useLandingScroll";


export default function Greeting() {
    const LIST_HELLO_TEXT = [
        'Bonjour',
        'Hola',
        'Ciao',
        'Hallo',
        'Olá',
        'Konnichiwa',
        'Guten Tag',
        'Namaste',
        'Salaam',
        'Merhaba',
        'Szia',
        'Hej',
        'Ahoj',
        'Hello ^.^',
    ];

    const GreetingRef = useRef<HTMLDivElement>(null);

    const progress = useLandingScroll({target: GreetingRef, startProgress: false});

    const y1 = useTransform(progress, [0, 1], [0, 400]);
    const y2 = useTransform(progress, [0, 1], [0, 200]);
    const scale = useTransform(progress, [0, 1], [1, 1.5]);

    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index !== LIST_HELLO_TEXT.length - 1) {
            setTimeout(() => {
                setIndex(index + 1)
            }, 150)
            return;
        }
    }, [index]);

    const helloText = useMemo(() => LIST_HELLO_TEXT[index % LIST_HELLO_TEXT.length], [index])

    const isShow = useMemo(() => index === LIST_HELLO_TEXT.length - 1, [index])

    const scaleY = useTransform(progress, [0, 1], [0, 1]);

    return (
        <div ref={GreetingRef} className={"GreetingLandingSection"}>
            <div className={"HelloTextSpace"}>
                <motion.h1
                    className={"hello"}>
                    {helloText}
                </motion.h1>
                <motion.h1
                    style={{y: y2, scale: scale}}
                    initial={{y: 100, opacity: 0}}
                    animate={isShow && {y: 0, opacity: 1}}
                    transition={{delay: 0.5, duration: 1}}
                    className={"HTName"}>
                    Huyen Tran
                </motion.h1>
            </div>
            <div className={"scrollDown"}>
                <motion.div
                    initial={{opacity: 0}}
                    animate={isShow && {opacity: 1}}
                    transition={{delay: 1.5, duration: 1}}
                    className={"content"}>
                    <div>
                        Scroll Down
                    </div>
                    <motion.div
                        initial={{y: 0}}
                        animate={{y: 15}}
                        transition={{repeat: Infinity, duration: 0.5, repeatType: "reverse"}}
                        className={"icon"}
                    >
                        <FaAngleDown/>
                    </motion.div>
                </motion.div>
            </div>

            <motion.div
                className={"sectionBg"}
                style={{scaleY}}>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 481">
                    <path className="cls-1"
                          fill={"#334155"}
                          d="M1366,489.83c0,188.97-1366,188.97-1366,0S329.51,11,683.56,11s682.44,289.86,682.44,478.83Z"/>
                </svg>
            </motion.div>
        </div>
    )
}