import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import './Init.scss'

function App() {
  useEffect(() => {
    document.title = "Hello Huyền Trân";
  }, []);

  return (
    <div className="App">
      <Routes>
          <Route path="/" element={<LandingPage/>}/>
      </Routes>
    </div>
  );
}

export default App;
