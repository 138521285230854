// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Miedinger-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Montserrat/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Montserrat/Montserrat-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Montserrat/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/monsieur-la-doulaise/MonsieurLaDoulaise-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/fonts/great-vibes/GreatVibes-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./assets/fonts/DN Cursive Regular/DN Cursive Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Miedinger-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Montserrat";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "MontserratThin";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
@font-face {
  font-family: "doulaise";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
}
@font-face {
  font-family: "greate-vibe";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
}
@font-face {
  font-family: "dn";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("truetype");
}`, "",{"version":3,"sources":["webpack://./src/Init.scss"],"names":[],"mappings":"AAsBA;EACE,6BAAA;EACA,+DAAA;AArBF;AAyBA;EACE,yBAAA;EACA,+DAAA;AAvBF;AA0BA;EACE,6BAAA;EACA,+DAAA;AAxBF;AA2BA;EACE,8BAAA;EACA,+DAAA;AAzBF;AA4BA;EACE,uBAAA;EACA,+DAAA;AA1BF;AA6BA;EACE,0BAAA;EACA,+DAAA;AA3BF;AA8BA;EACE,iBAAA;EACA,+DAAA;AA5BF","sourcesContent":["$points: (\n        xs: 0,\n        sm: 576px,\n        md: 768px,\n        lg: 992px,\n        xl: 1200px,\n        xxl: 1400px,\n        xxxl: 1600px,\n);\n\n@mixin media-up($breakpoint) {\n  @media (min-width: map-get($points, $breakpoint)) {\n    @content;\n  }\n}\n\n@mixin media-down($breakpoint) {\n  @media (max-width: map-get($points, $breakpoint) - 1px) {\n    @content;\n  }\n}\n\n@font-face {\n  font-family: 'Miedinger-Bold';\n  src: url('assets/fonts/Miedinger-Bold.ttf') format('truetype');\n}\n\n\n@font-face {\n  font-family: 'Montserrat';\n  src: url('assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'MontserratThin';\n  src: url('assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Montserrat-Bold';\n  src: url('assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'doulaise';\n  src: url('assets/fonts/monsieur-la-doulaise/MonsieurLaDoulaise-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'greate-vibe';\n  src: url('assets/fonts/great-vibes/GreatVibes-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'dn';\n  src: url('assets/fonts/DN Cursive Regular/DN Cursive Regular.ttf') format('truetype');\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
